import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getProfile } from "../../../services/api/myProfileApis";
import { updateUserData } from '../../../redux/reducers/authSlice';
import http from '../../../services/api/http';
import { useDispatch } from 'react-redux';

const generateState = () => {
    const state = uuidv4();
    // call the API to store the state
    http.patch('profile/add-state', { state, type: 'qbo' });
    return state;
};

const retrieveQboAuthInfo = async (oauthCode, realmId, state, setResponse, setUser, dispatch, redirectUri) => {
    console.log('calling qboAuthInfo');
    try {
        const data = await http.get(`/QboAuthInfo?code=${encodeURIComponent(oauthCode)}&realmId=${encodeURIComponent(realmId)}&state=${state}&redirect_uri=${encodeURIComponent(redirectUri)}`);
        setResponse(data);
    } catch (error) {
        console.error('Error fetching API:', error);
        setResponse({ error: error.message });
    }
    const profile = await getProfile();
    dispatch(updateUserData(profile?.data.userDetails));
    setUser(profile?.data.userDetails);
};

const AuthorizeQbo = () => {
    const [response, setResponse] = useState(null);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const initializationDone = useRef(false);


    // Combined effect for initialization
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        
        const initialize = async () => {
            if (initializationDone.current) return;
            initializationDone.current = true;
            
            // get url
            const redirectUri = window.location.origin + window.location.pathname;


            // Handle OAuth callback if present
            if (urlParams.has('code')) {
                const oauthCode = urlParams.get('code');
                const realmId = urlParams.get('realmId')
                const state = urlParams.get('state')
                await retrieveQboAuthInfo(oauthCode, realmId, state, setResponse, setUser, dispatch, redirectUri);
                // clear the URL parameters
                window.history.pushState({}, document.title, redirectUri);
            } else {
                // Just fetch profile if no OAuth callback
                const profile = await getProfile();
                dispatch(updateUserData(profile?.data.userDetails));
                setUser(profile?.data.userDetails);
            }
        };


        initialize();
    // eslint-disable-next-line
    }, []); // Empty dependency array - runs once on mount

    const handleAuthorize = () => {
        const qboClientId = 'AByD6LwBzTSONmBU9kPHxeyhtEIsctJ9j1tkLXkZh9cNrJqzY8';
        const state = generateState();
        // localStorage.setItem('state', state);
        const redirectUri = 'https://bireports.today/qbo_auth';
        // const redirectUri = 'http://localhost:3000/qbo_auth';

        const oauthUri = `https://appcenter.intuit.com/connect/oauth2?client_id=${qboClientId}&state=${state}&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirectUri}&response_type=code`;
        window.location.href = oauthUri;
    };

    const renderResponse = () => {
        if (response) {
            if (response.error) {
                return <div>Error: {response.error}</div>;
            }
            return <div>Added authentication for {response?.CompanyName}</div>;
        }
        return null;
    };

    const pageWhenNotConnected = () => {
        return (
            <section className="pr-details common-padd pt-0">
            <div className="container">
                <div className="pr-details-box">
                    <h2>QuickBooks Online</h2>
                    <div className="contact-details-right-wrap">
                        <div className="Contact-with-us">
                            {/* {renderResponse()} */}
                            <button onClick={
                                // for the button use image/C2QB_green_btn_med_default_2x.png
                                //padding: 0;border: none;background: none;

                                handleAuthorize} style={{ padding: 0, border: 'none', background: 'none' }}>
                                <img src="/images/C2QB_green_btn_med_default_2x.png" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }

    const pageWhenConnected = () => {
        return (
            <section className="pr-details common-padd pt-0">
            <div className="container">
                <div className="pr-details-box">
                    <h2>QuickBooks Online</h2>
                    <div className="contact-details-right-wrap">
                        <div className="Contact-with-us">
                            {/* {renderResponse()} */}
                            {user && (
                                <div>
                                    <h4>Connected Company:</h4>
                                    <ul>
                                        {(user?.qboInfo || []).map((id, index) => (
                                            // for each line in the array, display the company name and the disconnect blue button which calls QboAuthInfo/disconnect?realmId={id}
                                            // split the company name from the realmId
                                            // make the button look like a link
                                            <li key={id.realmId}>{id?.companyName} <button style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none', padding: 0 }} 
                                            onClick={() => {
                                                http.post(`/QboAuthInfo/disconnect?realmId=${id.realmId}`);
                                                setUser({
                                                    ...user,
                                                    qboInfo: user.qboInfo.filter((company) => company.realmId !== id.realmId)
                                                });
                                            }}>Disconnect</button></li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }

    if (user && user.qboInfo && user.qboInfo.length > 0) {
        return pageWhenConnected();
    } else {
        return pageWhenNotConnected();
    }

    return (
        <section className="pr-details common-padd pt-0">
            <div className="container">
                <div className="pr-details-box">
                    <h2>QuickBooks Online</h2>
                    <div className="contact-details-right-wrap">
                        <div className="Contact-with-us">
                            {renderResponse()}
                            <button onClick={
                                // for the button use image/C2QB_green_btn_med_default_2x.png
                                //padding: 0;border: none;background: none;

                                handleAuthorize} style={{ padding: 0, border: 'none', background: 'none' }}>
                                <img src="/images/C2QB_green_btn_med_default_2x.png" alt="" />
                            </button>
                            {user && (
                                <div>
                                    <h4>Connected Companies:</h4>
                                    <ul>
                                        {(user?.qboInfo || []).map((id, index) => (
                                            // for each line in the array, display the company name and the disconnect button which calls QboAuthInfo/disconnect?realmId={id}
                                            // split the company name from the realmId
                                            <li key={id.realmId}>{id?.companyName} <button onClick={() => {
                                                http.post(`/QboAuthInfo/disconnect?realmId=${id.realmId}`);
                                                setUser({
                                                    ...user,
                                                    qboInfo: user.qboInfo.filter((company) => company.realmId !== id.realmId)
                                                });
                                            }}>Disconnect</button></li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AuthorizeQbo;


